.tableStyle {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.thStyle {
  border: solid 2px;
  padding: 2px 3px;
  background-color: #a0a0d5;
}
