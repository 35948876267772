.selectBox {
  padding: 4px 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.selectBox:focus {
  border-color: #007bff;
  outline: none;
}

.selectContainer {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: bold;
}
