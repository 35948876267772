.separateLine {
  border-bottom: 1px solid #000;
}


.tableIndex {
  font-size: 9px;
  padding: 2px 4px 2px 4px;
  text-align: right;
}
.tablePoint,
.tableRawPoint
{
  margin: 4px 6px 4px 6px;
  text-align: right;
  font-size: 11px;
}

.tableChart {
  margin: 2px 3px;
  text-align: center;
  font-size: 12px;
  height: 100%;

  /* 上下中央揃え */
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;

  /* 左右中央揃え */
  justify-content: center
}

/* 曲名 / 歌手名 */
.tableSong {
  display: flex;
  flex-direction: column;
  height: 100%;
}
 
.tableSongNameArea {
  height: 61%;
  /* 上下中央揃え */
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
}
.tableSongNameArea .tableSongName {
  font-size: 10px;
  margin: 2px 4px 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableArtistNameArea {
  height: 39%;
  position: relative;
}
.tableArtistNameArea .tableArtistName {
  font-size: 7px;
  margin: 2px 4px 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableSongNameArea .tableSongName::after,
.tableArtistNameArea .tableArtistName::after {
  font-size: 10px;
  content: attr(data-full-text);
  position: absolute;
  bottom: 30%;
  left: 30%;
  width: max-content;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: normal;
  word-wrap: break-word;
  z-index: 10;
  transform: translateY(5px);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition-delay: 0.2s;
}

.tableSongNameArea .tableSongName:hover::after,
.tableArtistNameArea .tableArtistName:hover::after {
  opacity: 1;
  transform: translateY(0);
}
/* 曲名 / 歌手名 */

/* 選曲番号 */
.tableDateCount {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tableDateKeyArea {
    height: 50%;
    /* 上下左右中央揃え */
    display: flex;
    align-items: center;
    justify-content: center;
    
    .tableDateKey {
      padding: 2px 4px 2px 4px;
      display: flex;
      width: 100%;
      
      .tableDate {
        width: 75%;
        font-size: 10px;
        padding: 0px 2px 0px 0px;
        text-align: left;
      }
      .tableKey {
        width: 25%;
        font-size: 9px;
        padding: 0 0 0 2px;
        text-align: right;
      }
    }
  }
  .tableScoringCount {
    height: 50%;
    padding: 0px 4px 0px 4px;
    font-size: 11px;
    text-align: right;
  }
  /* 選曲番号 */
}
