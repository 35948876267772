.sortableThElem {
  font-size: 10.5px;
  cursor: pointer;
  font-weight: bold;
}

.thElem {
  font-size: 10.5px;
}

.asc {
  color: rgb(65, 90, 200);
}

.desc {
  color: rgb(245, 29, 22);
}
